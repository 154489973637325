@import url("https://fonts.cdnfonts.com/css/avenir-lt-std");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Avenir LT Std", sans-serif;
}

&::-webkit-scrollbar {
  display: block !important;
  width: 5px;
  background: black;
}

&::-webkit-scrollbar-thumb {
  background-color: rgb(90, 90, 90);
  border-radius: 5px;
}
/* .css-3iigni-container {
  pointer-events: auto !important;
} */
#multiselect-dropdown {
  pointer-events: auto;
}
