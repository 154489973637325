/* Custom Styling for react-phone-input-2 */
#phone-input-wrapper .country-list .country:hover {
  background-color: #edae49;
}

#phone-input-wrapper .country-list .country.highlight {
  background-color: #edae49;
}

#phone-input-wrapper .react-tel-input input {
  padding: 0.625rem 2.5rem;

  &::placeholder {
    color: #b5b5b5;
  }

  @media screen and (max-width: 1380px) {
    padding: 0.625rem 3.5rem;
  }
}
