.position-relative {
  position: relative;
}

html,
body {
  font-size: 12px;
}

@media (min-width: 48em) {
  html,
  body {
    font-size: 14px;
  }
}

@media (min-width: 64em) {
  html,
  body {
    font-size: 16px;
  }
}

@media (min-width: 85.375em) {
  html,
  body {
    font-size: 18px;
  }
}

@media (min-width: 120em) {
  html,
  body {
    font-size: 20px;
  }
}

@media (min-width: 160em) {
  html,
  body {
    font-size: 24px;
  }
}

@media (min-width: 180em) {
  html,
  body {
    font-size: 32px;
  }
}

@media (min-width: 200em) {
  html,
  body {
    font-size: 40px;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.d-flex {
  display: flex;
}

.disabled {
  cursor: not-allowed !important;
}
svg.disabled {
  opacity: 0.6;
}
